"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNI_V2_JINX_STAKING_REWARDS_CONTRACTS = exports.COWSWAP_CONTRACT_MAINNET = exports.UNI_V2_JINX_STAKING_REWARDS_V5 = exports.UNI_V2_JINX_STAKING_REWARDS_V4 = exports.UNI_V2_JINX_STAKING_REWARDS_V3 = exports.UNI_V2_JINX_STAKING_REWARDS_V2 = exports.UNI_V2_JINX_STAKING_REWARDS_V1 = exports.WETH_CONTRACT_ROPSTEN = exports.WETH_CONTRACT_MAINNET = exports.JINXY_STAKING_CONTRACT = exports.THOR_ROUTER_CONTRACT_AVAX_MAINNET = exports.THOR_ROUTER_CONTRACT_ETH_MAINNET = exports.UNI_V2_ROUTER_CONTRACT = exports.SHAPE_SHIFT_ROUTER_CONTRACT = void 0;
exports.SHAPE_SHIFT_ROUTER_CONTRACT = '0x6a1e73f12018D8e5f966ce794aa2921941feB17E';
exports.UNI_V2_ROUTER_CONTRACT = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
exports.THOR_ROUTER_CONTRACT_ETH_MAINNET = '0xC145990E84155416144C532E31f89B840Ca8c2cE';
exports.THOR_ROUTER_CONTRACT_AVAX_MAINNET = '0x8F66c4AE756BEbC49Ec8B81966DD8bba9f127549';
exports.JINXY_STAKING_CONTRACT = '0xee77aa3Fd23BbeBaf94386dD44b548e9a785ea4b';
exports.WETH_CONTRACT_MAINNET = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
exports.WETH_CONTRACT_ROPSTEN = '0xc778417E063141139Fce010982780140Aa0cD5Ab';
exports.UNI_V2_JINX_STAKING_REWARDS_V1 = '0xDd80E21669A664Bce83E3AD9a0d74f8Dad5D9E72';
exports.UNI_V2_JINX_STAKING_REWARDS_V2 = '0xc54B9F82C1c54E9D4d274d633c7523f2299c42A0';
exports.UNI_V2_JINX_STAKING_REWARDS_V3 = '0x212ebf9FD3c10F371557b08E993eAaB385c3932b';
exports.UNI_V2_JINX_STAKING_REWARDS_V4 = '0x24FD7FB95dc742e23Dc3829d3e656FEeb5f67fa0';
exports.UNI_V2_JINX_STAKING_REWARDS_V5 = '0xC14eaA8284feFF79EDc118E06caDBf3813a7e555';
exports.COWSWAP_CONTRACT_MAINNET = '0x9008D19f58AAbD9eD0D60971565AA8510560ab41';
exports.UNI_V2_JINX_STAKING_REWARDS_CONTRACTS = [
    exports.UNI_V2_JINX_STAKING_REWARDS_V1,
    exports.UNI_V2_JINX_STAKING_REWARDS_V2,
    exports.UNI_V2_JINX_STAKING_REWARDS_V3,
    exports.UNI_V2_JINX_STAKING_REWARDS_V4,
    exports.UNI_V2_JINX_STAKING_REWARDS_V5,
];
