"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/highbury-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenBalanceToJSON = exports.TokenBalanceFromJSONTyped = exports.TokenBalanceFromJSON = exports.instanceOfTokenBalance = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the TokenBalance interface.
 */
function instanceOfTokenBalance(value) {
    let isInstance = true;
    isInstance = isInstance && "contract" in value;
    isInstance = isInstance && "decimals" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "symbol" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "balance" in value;
    return isInstance;
}
exports.instanceOfTokenBalance = instanceOfTokenBalance;
function TokenBalanceFromJSON(json) {
    return TokenBalanceFromJSONTyped(json, false);
}
exports.TokenBalanceFromJSON = TokenBalanceFromJSON;
function TokenBalanceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contract': json['contract'],
        'decimals': json['decimals'],
        'name': json['name'],
        'symbol': json['symbol'],
        'type': json['type'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'balance': json['balance'],
    };
}
exports.TokenBalanceFromJSONTyped = TokenBalanceFromJSONTyped;
function TokenBalanceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contract': value.contract,
        'decimals': value.decimals,
        'name': value.name,
        'symbol': value.symbol,
        'type': value.type,
        'id': value.id,
        'balance': value.balance,
    };
}
exports.TokenBalanceToJSON = TokenBalanceToJSON;
